const isRouteValidService = () => {
    const { contact_number, type, first_name, last_name, birthdate, city, barangay, street, region, zip_code, email, q1, q2, q3, q4 } = JSON.parse(localStorage.getItem('data'));
    return new Promise((resolve, reject) => {
        if (contact_number && type && first_name && last_name && birthdate && city && barangay && street && email && region && zip_code && q1 && q2 && q3 && q4) {
            resolve(true)
        } else {
            reject(false)
        }
    });
}

export default isRouteValidService;