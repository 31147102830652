export const dataReducer = (state = {}, action) => {
    switch (action.type) {
        case 'UPDATE_FORM_DATA':
            state = action.payload;
            return state;
        case 'RESET_FORM_DATA':
            return {};
        default:
            return {};
    }
}