import './Home.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function HomePage() {

    const dataReducer = useSelector(state => state.dataReducer);

    return (
        <div className="Home container">

            <div className="home__wrapper">
                <img alt="Logo" className="logo" src="/bb-logo.png" />
                <h1 className="section-title">CONTACT TRACING</h1>

                {dataReducer}

                <p className="text--gray">
                    Welcome to the Bigbulb Staff House!
                    Kindly fill up the following Health Declaration, in compliance to the  health regulations of the DOH and IATF-EID.
                    <br /><br />
                    <span className="text-italic text--gray">(Welcome to the Bigbulb Staff House! Paki fill-up lang pô ang Health Declaration na itó, alinsunod sa mga alituntuning pangkalusugan ng DOH at IATF-EID.)</span>
                </p>

                <Link className="btn btn-primary" to="/contact-tracing">Continue</Link>

            </div>
        </div>
    );
}

export default HomePage;