import './BottomCta.scss';
import { Link } from 'react-router-dom';
import { save } from './save.service';
import { useHistory } from "react-router-dom";
import LoaderComponent from './../loader';
import { useState } from 'react';



function BottomCtaComponent({ nextPath, backPath = null, goSave = false, enabled = false }) {
    const history = useHistory();
    const [loader, setLoader] = useState(false);


    const saveData = () => {
        setLoader(true);
        save().then(response => {
            history.push('/thank-you');
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            console.log(error);
        })
    }



    let backCta, nextCta, goSaveCta;
    backCta = backPath ? <Link to={backPath}>Back</Link> : '';

    if (enabled) {
        nextCta = nextPath ? <Link className="btn btn-primary right" to={nextPath}>Next</Link> : '';

        if (goSave) {
            nextCta = null;
            goSaveCta = <button className="btn btn-primary right" onClick={saveData}>Submit</button>
        }
    }



    return (
        <div className="BottomCta">
            {loader ? <LoaderComponent /> : ''}
            <div className="container">
                {backCta}
                {nextCta}
                {goSaveCta}
            </div>

        </div>
    );
};

export default BottomCtaComponent;