import { Link } from 'react-router-dom';
function FormHeaderComponent({ title }) {
    return (
        <div className="contact-tracing__header">
            <Link to="/"><img alt="Logo" className="logo" src="/bb-logo.png" /></Link>
            <h1 className="section-title">{title}</h1>
        </div>
    );
}

export default FormHeaderComponent;