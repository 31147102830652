import BottomCtaComponent from '../../bottom-cta';
import FormHeaderComponent from '../../form-header';
// import HealthDeclarationModel from './health-declaration.model';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateData as UPDATE_DATA } from '../../../store/actions/data-action';

function HealthDeclarationPage() {

    const [data, setData] = useState(JSON.parse(localStorage.getItem('data')) || {
        contact_number: '',
        type: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        birthdate: '',
        city: '',
        barangay: '',
        district: '',
        street: '',
        bldg_name: '',
        house_no: '',
        email: '',
        region: '',
        zip_code: '',
        q1: '',
        q2: '',
        q3: '',
        q4: []
    });
    const dispatch = useDispatch();

    const updateInternalData = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const choices = [
        'No to all',
        'sore throat',
        'cough',
        'chills',
        'body aches for inknown reasons',
        'loss of smell',
        'loss of taste',
        'fever at or greater than 38 degree celsius'
    ];

    const handleCheckboxChange = (event) => {
        let value = event.target.value;
        let isChecked = event.target.checked;

        if (value === 'No to all') {
            if (isChecked) {
                data.q4 = ['No to all'];

                return setData({
                    ...data,
                    q4: data.q4
                });
            }

        }

        if (data.q4.length) {
            const index = data.q4.indexOf('No to all');
            if (index !== -1) {
                data.q4.splice(index, 1);
            }

        }

        if (data.q4.includes(value)) {
            if (!isChecked) {
                data.q4.forEach((item, index) => {
                    if (item === value) {
                        data.q4.splice(index, 1);
                    }
                })
            }
        } else {
            data.q4.push(value);
        }


        setData({
            ...data,
            q4: data.q4
        });


    }

    /** CHECK VALIDITY **/
    const [isValid, setValidaity] = useState(false);
    const checkValidity = () => {
        if (data.q1 && data.q2 && data.q3 && data.q4.length) {
            setValidaity(true)
        } else {
            setValidaity(false)
        }
    };

    useEffect(() => {
        dispatch(UPDATE_DATA(data));
        checkValidity();
        localStorage.setItem('data', JSON.stringify(data));
    });

    return (
        <div className="HealthDeclaration ContactTracingPage container">
            <FormHeaderComponent title="Health Declaration" />
            <div className="form-group">
                <p>In the past 14 days, have you been tested POSITIVE for COVID-19?</p>
                <p className="text-italic text--gray">Sa mga nakaraáng 14 araw, nag-positibo pô ba kayó sa kahit anóng test para sa Covid-19?</p>
                <div className="form-group form-group-checkbox">
                    <input checked={data.q1 === 'Yes'} onChange={updateInternalData} name="q1" id="q1_yes" type="radio" value="Yes" />
                    <span></span>
                    <label htmlFor="q1_yes">Yes</label>
                </div>

                <div className="form-group form-group-checkbox">
                    <input checked={data.q1 === 'No'} onChange={updateInternalData} name="q1" id="q1_no" type="radio" value="No" />
                    <span></span>
                    <label htmlFor="q1_no">No</label>
                </div>
            </div>

            <div className="form-group">
                <p>In the past 5 days, have you been evaluated as Probable or Suspect for COVID-19?</p>
                <p className="text-italic text--gray">Sa mga nakaraáng 5 araw, naituring pô ba kayóng Probable or Suspect para sa Covid-19?</p>
                <div className="form-group form-group-checkbox">
                    <input checked={data.q2 === 'Yes'} onChange={updateInternalData} name="q2" id="q2_yes" type="radio" value="Yes" />
                    <span></span>
                    <label htmlFor="q2_yes">Yes</label>
                </div>

                <div className="form-group form-group-checkbox">
                    <input checked={data.q2 === 'No'} onChange={updateInternalData} name="q2" id="q2_no" type="radio" value="No" />
                    <span></span>
                    <label htmlFor="q2_no">No</label>
                </div>
            </div>

            <div className="form-group">
                <p>In the past 14 days, did you come in close contact or staying in the same close environment with someone who is a confirmed COVID-19 case or Suspected person WITHOUT wearing properly the recommended Personal Protective Equipment (PPE) (e.g. face mask)?</p>
                <p className="text-italic text--gray">Sa mga nakaraáng 14 araw, may nagíng close contact o housemate pô ba kayóng Suspected o Confirmed na Covid-19 case na hindî nagpi-PPE?</p>
                <div className="form-group form-group-checkbox">
                    <input checked={data.q3 === 'Yes'} onChange={updateInternalData} name="q3" id="q3_yes" type="radio" value="Yes" />
                    <span></span>
                    <label htmlFor="q3_yes">Yes</label>
                </div>

                <div className="form-group form-group-checkbox">
                    <input checked={data.q3 === 'No'} onChange={updateInternalData} name="q3" id="q3_no" type="radio" value="No" />
                    <span></span>
                    <label htmlFor="q3_no">No</label>
                </div>
            </div>

            <div className="form-group">
                <p>In the past 24 hours, have you had the following symptoms? Please select one or more symptoms that applies to you.</p>
                <p className="text-italic text--gray">Sa mga nakaraáng 24 oras, kayó pô ba ay nagkaroón ng mga sumusunod na karadaman? Paki pilì po ang lahát na naaangkop.</p>
                {choices.map((item, index) => {
                    let id = `multiple_choice_${index}`;
                    return <div key={id} className="form-group form-group-checkbox">

                        <input checked={data.q4.includes(item)} onChange={handleCheckboxChange} id={id} name="q4" type="checkbox" value={item} />
                        <span></span>
                        <label htmlFor={id}>{item}</label>
                    </div>
                })}
            </div>
            <BottomCtaComponent enabled={isValid} goSave={true} nextPath="/thank-you" backPath="/contact-tracing" />
        </div >
    );
}

export default HealthDeclarationPage;