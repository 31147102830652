import './ContactTracing.scss';

import BottomCtaComponent from '../../bottom-cta';
import FormHeaderComponent from '../../form-header';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateData as UPDATE_DATA } from '../../../store/actions/data-action';

function ContactTracingPage() {
    const dispatch = useDispatch();
    const [data, setData] = useState(JSON.parse(localStorage.getItem('data')) || {
        contact_number: '',
        type: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        birthdate: '',
        city: '',
        barangay: '',
        district: '',
        street: '',
        bldg_name: '',
        house_no: '',
        email: '',
        region: '',
        zip_code: '',
        q1: '',
        q2: '',
        q3: '',
        q4: []
    });

    const updateInternalData = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    /** CHECK VALIDITY **/
    const [isValid, setValidaity] = useState(false);
    const checkValidity = () => {
        if (data.contact_number && data.type && data.first_name && data.last_name && data.birthdate && data.city && data.barangay && data.street && data.email) {
            setValidaity(true)
        } else {
            setValidaity(false)
        }
    };


    useEffect(() => {
        dispatch(UPDATE_DATA(data));
        checkValidity();
        localStorage.setItem('data', JSON.stringify(data));
    });

    return (
        <div className="ContactTracingPage container">

            <FormHeaderComponent title="Personal Information" />

            <div className="form-group">
                <label>Contact Number*</label>
                <input value={data.contact_number} name="contact_number" onChange={updateInternalData} type="number" />
            </div>
            <div className="form-group">
                <label>Email*</label>
                <input value={data.email} name="email" onChange={updateInternalData} type="email" />
            </div>
            <div className="form-group">
                <label>Type</label>
                <select value={data.type} onChange={updateInternalData} name="type">
                    <option></option>
                    <option value="Employee">Employee</option>
                    <option value="Contractor/Supplier">Contractor/Supplier</option>
                    <option value="Client">Client</option>
                    <option value="Visitor">Visitor</option>
                </select>
            </div>

            <div className="form-group">
                <label>First Name*</label>
                <input value={data.first_name} onChange={updateInternalData} name="first_name" type="text" />
            </div>
            <div className="form-group">
                <label>Middle Name</label>
                <input value={data.middle_name} onChange={updateInternalData} name="middle_name" type="text" />
            </div>
            <div className="form-group">
                <label>Last Name*</label>
                <input value={data.last_name} onChange={updateInternalData} name="last_name" type="text" />
            </div>
            <div className="form-group">
                <label>Birthdate*</label>
                <input value={data.birthdate} onChange={updateInternalData} name="birthdate" type="date" />
            </div>


            <h2 className="text-link-styled">Address</h2>

            
            <div className="form-group">
                <label>House/ Block/ Package/ Bldg Name*</label>
                <input value={data.house_no} onChange={updateInternalData} name="house_no" type="text" />
            </div>

            <div className="form-group">
                <label>Street Address*</label>
                <input value={data.street} name="street" onChange={updateInternalData} type="text" />
            </div>

            <div className="form-group">
                <label>Village/Subdivision</label>
                <input value={data.district} name="district" onChange={updateInternalData} type="text" />
            </div>

            <div className="form-group">
                <label>Barangay*</label>
                <input value={data.barangay} name="barangay" onChange={updateInternalData} type="text" />
            </div>

            <div className="form-group">
                <label>City*</label>
                <input value={data.city} onChange={updateInternalData} name="city" type="text" />
            </div>


            <div className="form-group">
                <label>Region*</label>
                <input value={data.region} onChange={updateInternalData} name="region" type="text" />
            </div>

            <div className="form-group">
                <label>Zip Code*</label>
                <input value={data.zip_code} onChange={updateInternalData} name="zip_code" type="text" />
            </div>


            <BottomCtaComponent enabled={isValid} nextPath="/health-declaration" backPath="/" />

        </div >
    );
}

export default ContactTracingPage;