import './ThankYou.scss';

import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import isRouteValidService from './../../../services/route-guard.service';
function ThankYouPage() {

    const history = useHistory();
    useEffect(() => {
        isRouteValidService().catch(error => {
            history.push('/');
        });
    });



    return (
        <div className="container ThankYou text-center">
            <div>
                <h1 className="section-title page-title">Thank You!</h1>
                
                <br />
                <br />
                <a href="https://bigbulb.ph/" className="text-link-styled">www.bigbulb.ph</a>
            </div>
        </div>
    );
}

export default ThankYouPage;