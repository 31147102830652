
import './App.scss';
import HomePage from './components/pages/home';
import ContactTracingPage from './components/pages/contact-tracing';
import HealthDeclarationPage from './components/pages/health-declaration';
import ThankYouPage from './components/pages/thank-you';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top'




function App() {
  return (
    <Router>
      <ScrollToTop>
        <div className="App">
          <Switch>
            <Route exact path="/health-declaration" component={HealthDeclarationPage} />
            <Route exact path="/contact-tracing" component={ContactTracingPage} />
            <Route exact path="/thank-you" component={ThankYouPage} />
            <Route path="/" exact component={HomePage} />
          </Switch>
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
